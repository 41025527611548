<template>
  <ui-component-modal
    :modalTitle="$t('Components.Reservation.ModalEditContact.Header_Title')"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveContact"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td>
              {{ $t('Components.Reservation.ModalEditContact.Label_Type') }}
            </td>
            <td>
              <div class="select">
                <select v-model="mContact.Type">
                  <option
                    v-for="(contactType, index) in contactTypes"
                    :key="index"
                    :value="contactType.Type"
                    :selected="contactType.Type === mContact.Type"
                    >{{ contactType.Name }}</option
                  >
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Components.Reservation.ModalEditContact.Label_Name') }}
            </td>
            <td>
              <input type="text" class="input" v-model="mContact.Name" />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Components.Reservation.ModalEditContact.Label_Email') }}
            </td>
            <td>
              <input type="text" class="input" v-model="mContact.Email" />
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('Components.Reservation.ModalEditContact.Label_Phone') }}
            </td>
            <td>
              <input type="text" class="input" v-model="mContact.Phone" />
            </td>
          </tr>
          <tr>
            <td>
              {{
                $t('Components.Reservation.ModalEditContact.Label_ReceiveEmail')
              }}
            </td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': mContact.ReceiveEmail }">
                    <a @click="mContact.ReceiveEmail = true">
                      <span>{{
                        $t('Components.Reservation.ModalEditContact.Label_Yes')
                      }}</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': !mContact.ReceiveEmail }">
                    <a @click="mContact.ReceiveEmail = false">
                      <span>{{
                        $t('Components.Reservation.ModalEditContact.Label_No')
                      }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

export default {
  props: {
    contact: null,

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      contactTypes: [],
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mContact: this.contact,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.mContact = JSON.parse(JSON.stringify(this.contact))

    this.contactTypes.push({
      TypeId: 0,
      Type: 'Booker',
      Name: this.$t('Components.Reservation.ModalEditContact.Type_Booker'),
    })
    this.contactTypes.push({
      TypeId: 1,
      Type: 'DuringEvent',
      Name: this.$t('Components.Reservation.ModalEditContact.Type_DuringEvent'),
    })
    this.contactTypes.push({
      TypeId: 2,
      Type: 'Trainer',
      Name: this.$t('Components.Reservation.ModalEditContact.Type_Trainer'),
    })
    this.contactTypes.push({
      TypeId: 3,
      Type: 'Speaker',
      Name: this.$t('Components.Reservation.ModalEditContact.Type_Speaker'),
    })
    this.contactTypes.push({
      TypeId: 5,
      Type: 'Host',
      Name: this.$t('Components.Reservation.ModalEditContact.Type_Host'),
    })
    this.contactTypes.push({
      TypeId: 4,
      Type: 'Invoice',
      Name: this.$t('Components.Reservation.ModalAddContact.Type_Invoice'),
    })
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    saveContact() {
      let self = this
      self.isSaving = true

      reservationProvider.methods
        .updateReservationContact(
          self.reservation.Id,
          self.contact.Id,
          self.mContact
        )
        .then((response) => {
          if (response.status === 200) {
            self.isSavingSuccess = true

            let contactIndex = self.reservation.Contacts.findIndex(
              (c) => c.Id === self.contact.Id
            )
            if (contactIndex > -1) {
              let reservation = JSON.parse(JSON.stringify(self.reservation))
              Vue.set(reservation.Contacts, contactIndex, response.data)
              self.setReservation(reservation)
            }

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)

            EventBus.$emit('showToast', {
              type: 'info',
              message: self.contact.Name + ' is updated!',
            })
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
